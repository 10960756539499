import Swal from 'sweetalert2';
import errorIcon from '../../assets/alertErrorIcon.svg';
import successIcon from '../../assets/alertSuccessIcon.svg';

export const showError = async (message, timer) => {
    Swal.fire({
        html: `<div class="swal-container-error">
        <img src=${errorIcon} class="swal-icon" alt="red alert" />
        <p class="swal-text mb-0">${ message ? message : 'Ocurrió un error. Reinténtalo.'}</p>
        </div>`,
        timer: timer ? timer : 2000,
        showConfirmButton: false,
        background: 'hsla(0, 0%, 77%, 0)',
        backdrop: false,
        position: 'top-end',
        width: 0
    });
}


export const showSuccess = async (message, timer) => {
    Swal.fire({
        html: `<div class="swal-container-success">
        <img src=${successIcon} class="swal-icon" alt="green check" />
        <p class="swal-text mb-0">${ message ? message : 'Se completó la tarea con éxito.'}</p>
        </div>`,
        timer: timer ? timer : 2000,
        showConfirmButton: false,
        background: 'hsla(0, 0%, 77%, 0)',
        backdrop: false,
        position: 'top-end',
        width: 0
    });
}

export const showErrorWoo = async (title = '', message) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: message,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: `Aceptar`,
        cancelButtonColor: '#d33',
        backdrop: `rgba(0, 0, 0, 0.6)`
      }).then((result) => {
          if (result.isDismissed) {
              window.close(); 
          }
      })
}

export const confirmWoo = async (title = '', message = '') => {
    return Swal.fire({
        icon: 'question',
        title: title,
        text: message,
        showCancelButton: true,
        cancelButtonText: `Cerrar`,
        confirmButtonText: `Cancelar Envio`,
        customClass: {
            cancelButton: "swal-cancel-button-woo"
        },
        confirmButtonColor: '#FF6C0E',
        backdrop: `rgba(0, 0, 0, 0.6)`
      })
}

export const infoWoo = async (messageSuccess = '', messageError = '') => {
    const success = messageSuccess ? `
    <img src=${successIcon} class="swal-icon" alt="green check" />
    <p class="swal-text">${ messageSuccess ? messageSuccess : 'Se completó la tarea con éxito.'}</p>` : '';
    const error = messageError ? `
    <img src=${errorIcon} class="swal-icon" alt="green check" />
    <p class="swal-text">${ messageError ? messageError : 'Se completó la tarea con éxito.'}</p>` : '';

    return Swal.fire({
        html: `<div class="swal-container-woo">
        ${success}
        </div>
        <div class="swal-container-woo">
        ${error}
        </div>`,
        showCancelButton: false,
        confirmButtonText: `Cerrar`,
        confirmButtonColor: '#FF6C0E',
        backdrop: `rgba(0, 0, 0, 0.6)`
      })
}